<template>
    <div class="medee_and_medeelel">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="news_list_title">
                        <span>{{ 'news1' | translate }}</span>{{ 'information' | translate }}
                        <div class="select">
                            <Select v-model="selectId" style="width:200px" aria-placeholder="Salbar"
                                    :placeholder="'select' | translate">
                                <Option v-for="cat in categorys" :value="cat.id" :key="cat.id">
                                  <div v-if="language.short == 'mn'">{{ cat.medee_angilal }}</div>
                                  <div v-else>{{ cat.medee_angilal_en }}</div>
                                </Option>
                            </Select>
                            <Button shape="circle" icon="ios-search" style="margin-left: 10px" @click="getNewsList"></Button>
                        </div>

                    </div>
                </div>
                <div v-if="loading" class="loading-wrap">
                    <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div class="col-md-12 list">
                    <ul class="list_post">
                        <li v-for="(newsAngilal, index) in news"  :key="index"
                            class="clearfix">
                            <div class="row post_style">
                                <div class=" col-md-3 post_image ">
                                    <a href="">
                                        <img :src="image_url_mbua + newsAngilal.zurag" class="img-fluid">
                                    </a>
                                </div>
                                <div class="col-md-9">
                                    <router-link :to="'/news_sub/' + newsAngilal.id" style="text-decoration: none">
                                        <div class="post_content">
                                            <h5 class="post_title">
                                                <router-link :to="'/news_sub/' + newsAngilal.id" >
                                                  <div v-if="language.short == 'mn'">  {{ newsAngilal.garchig_mn }}</div>
                                                  <div v-else>  {{ newsAngilal.garchig_en }}</div>
                                                </router-link>
<!--                                                <router-link :to="'/news_sub/' + newsAngilal.id" >-->
<!--                                                    {{ newsAngilal.garchig_en }}-->
<!--                                                    &lt;!&ndash;БАРИЛГЫН ҮЙЛ АЖИЛЛАГААНЫ ТУСГАЙ ЗӨВШӨӨРӨЛ БҮРЭН ЦАХИМЖИХ ҮЙЛ АЖИЛЛАГАА ҮРГЭЛЖИЛЖ БАЙНА.                                                   &ndash;&gt;-->
<!--                                                </router-link>-->
                                            </h5>
                                            <p class="np_text" v-if="language.short == 'mn'" v-html="newsAngilal.medee_mn"></p>
                                            <p class="np_text" v-else v-html="newsAngilal.medee_en"></p>
                                            <p class="post_date">
                                                <a class=" float-left np_btn" v-if="language.short == 'mn'" >{{ newsAngilal.medee_angilal }}</a>
                                                <a class=" float-left np_btn" v-else >{{ newsAngilal.medee_angilal_en }}</a>
                                                <span class="date">{{ newsAngilal.updated_at | date }}</span>
                                            </p>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="pagenation">
                        <Page @on-change="changePage"
                              :current-page="current_page"
                              :page-size="10"
                              :total="total"
                              size="small"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {GET_NEWS, GET_MEDEE_ANGILAL, GET_NEWS_BY_ID} from "../../graphql/queries";
import {IMAGE_URL} from "../../graphql/queries";
import {mapGetters} from 'vuex';

export default {
    name: "p_news",
    components: {},
    data() {
        return {
            loading: true,
            selectId: null,
            categorys: [],
            news: [],
            total: 0,
            current_page: 1,
            last_page: 1,
            image_url_mbua: IMAGE_URL
        }
    },
    computed: {
        ...mapGetters([
            'languages',
            'language',
            // ...
        ])
    },
    mounted() {
        this.getCategory();
        this.getNewsList();
        // this.subNewsList();
    },
    methods: {
        getCategory() {
            this.$apollo.query({query: GET_MEDEE_ANGILAL}).then(({data}) => {
                this.categorys = data.ds_medee_angilal_view;
            })
        },
        getNewsList() {
            this.loading = true;
            // console.log(this.selectId);
            if (this.selectId) {
                this.$apollo.query({
                    query: GET_NEWS_BY_ID,
                    variables: {page: this.current_page, size: 10, cat_id: this.selectId.toString()}
                }).then(({data}) => {
                    this.news = data.paginate.ds_medee_view;
                    this.total = data.paginate.total;

                    setTimeout(() => {
                        this.loading = false;
                    })
                })
            } else {
                this.$apollo.query({
                    query: GET_NEWS,
                    variables: {page: this.current_page, size: 10}
                }).then(({data}) => {
                    this.news = data.paginate.ds_medee_view;
                    this.total = data.paginate.total;

                    setTimeout(() => {
                        this.loading = false;
                    })
                })
            }
        },
        changePage(page) {
            this.current_page = page;
            this.getNewsList();
        },
    },
}
</script>

<style scoped>

</style>